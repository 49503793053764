import React from "react";
import useRoute from "../../../hooks/useRoute";
import useBuildCustomer from "../../../hooks/useBuildCustomer";
import useCurrentCustomerStore from "../../../stores/CustomerStore";

import { saveCustomer } from "../../../api/CustomerAPI";
import { saveSuccessAlert, saveFailAlert } from "../../../utils/alertUtils";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import { PATHS } from "../../../constants/Constants";
import {
	buildCurrentAddress,
	buildMailingAddress,
	hasValidFields,
} from "./validAddressUtil";

import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import shallow from "zustand/shallow";
import useUserPrivileges from "../../settings/subFeatures/settingsTabs/userManagement/useUserPrivileges";

// Saves a new customer's information
const SaveNewCustomerButton = ({ updateDeal }) => {
	// Routes
	const route = useRoute(PATHS.LEAD_DETAIL);
	const history = useHistory();
	const privileges = useUserPrivileges();

	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Get customer details object
	const customer = useBuildCustomer();

	// Customer store
	const { editID } = useCurrentCustomerStore(
		(state) => ({
			editID: state.editID,
		}),
		shallow
	);

	// Saves the new customer to the database, then calls function save their address
	const saveCustomerInfo = (data) => {
		const p = privileges();
		if (!p.customerNew) {
			saveFailAlert("User does not have permission to save new customer");
			return;
		}
		const message = "All required or all empty";

		const currentAddress = buildCurrentAddress(data, dealerID, locationID);
		const mailingAddress = buildMailingAddress(data, dealerID, locationID);
		const validCurrentAddress = hasValidFields(currentAddress);
		const validMailingAddress = hasValidFields(mailingAddress);

		if (!validCurrentAddress || !validMailingAddress) {
			if (!validCurrentAddress) {
				methods.setError("currentCity", { message });
				methods.setError("currentState", { message });
				methods.setError("currentZip", { message });
				//methods.setError("currentCounty", { message });
				//methods.setError("currentOwnership", { message });
			}

			if (!validMailingAddress) {
				methods.setError("mailCity", { message });
				methods.setError("mailState", { message });
				methods.setError("mailZip", { message });
				//methods.setError("mailCounty", { message });
			}

			return;
		}

		const newCustomerData = {
			customer,
			currentAddress,
			mailingAddress,
		};
		let getZip = methods.getValues("currentZip");

		saveCustomer(newCustomerData).then(
			(response) => {
				console.log(response.data.content);

				editID(response.data.content.customer.ID);

				if (updateDeal) {
					if (getZip.length > 0) {
						updateDeal(response.data.content);
						saveSuccessAlert();
					} else
						methods.setError("currentZip", {
							type: "Required",
							message: "Zip Required",
						});
				} else {
					saveSuccessAlert();
					history.push(route, { ID: response.data.content.customer.ID });
				}
			},
			(error) => {
				console.log(error);
				saveFailAlert();
			}
		);
	};

	// Use the form provider context to get access to the methods
	const methods = useFormContext();
	// Function to handle the save button click and stop propagation
	const handleSaveClick = (event) => {
		event.stopPropagation(); // Prevent the event from propagating to lower elements
		methods.handleSubmit(saveCustomerInfo)();
	};

	return (
		<div>
			<div
				className="btn btn-success float-button hi-z-index"
				type="submit"
				onClick={handleSaveClick}
			>
				<i className="nc-icon nc-check-2" /> Save
			</div>
		</div>
	);
};

export default SaveNewCustomerButton;
