import React from "react";
import { Card, Table } from "reactstrap";
import dayjs from "dayjs";
import Switch from "react-bootstrap-switch";
import { showSwalMessage } from "../../../utils/alertUtils";

const ScheduledMessageTable = ({ scheduledMessages }) => {
	console.log(scheduledMessages);
	if (!scheduledMessages || scheduledMessages.length === 0) return null;
	const handleMessageStatus = (active, id) => {
		console.log(active, id);
		if (active) {
			console.log("Active");
			//updateMessageStatus(id, 1);
		} else {
			console.log("Inactive");
			//updateMessageStatus(id, 0);
		}
	};
	const handleView = (message) => {
		console.log(message);
		const htmlContent = `
		<textarea style="width:100%; height:200px;" readonly>${message}</textarea>
	`;
		showSwalMessage("Message", htmlContent, "");
	};

	return (
		<Card className="mt-4">
			<Table bordered responsive>
				<thead>
					<tr>
						<th>#</th>
						<th>Type</th>
						<th>Start Date</th>
						<th>Expires</th>
						<th>Frequency</th>
						<th>#Executed</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{scheduledMessages.map((msg, index) => (
						<tr key={msg.id || index}>
							<td>{index + 1}</td>
							{/* <td>{msg.message}</td> */}
							<td>
								{msg.cellEmailBoth === 0
									? "SMS"
									: msg.cellEmailBoth === 1
									? "Email"
									: msg.cellEmailBoth === 2
									? "Both"
									: "Reminder"}
							</td>
							<td>{dayjs(msg.startDate).format("MM/DD/YYYY h:mm A")}</td>
							<td>{dayjs(msg.expirationDate).format("MM/DD/YYYY h:mm A")}</td>
							<td>
								{
									["One Time", "Daily", "Weekly", "Monthly", "Yearly"][
										msg.frequency || 0
									]
								}
							</td>
							<td>{msg.totalExecutedTimes}</td>
							{/* <td>{msg.active ? "Active" : "Inactive"}</td> */}
							<td>
								<Switch
									disabled={!msg.active}
									value={msg.active}
									onChange={() => handleMessageStatus(msg.active, msg.ID)}
								/>
							</td>
							<td>
								<button
									className="btn btn-sm btn-primary"
									onClick={() => handleView(msg.message)}
								>
									View
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Card>
	);
};

export default ScheduledMessageTable;
