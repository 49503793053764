import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	FormGroup,
	Progress,
	Row,
} from "reactstrap";
import shallow from "zustand/shallow";

import {
	getCreditAppListByDateRange,
	getCreditAppListBySearch,
} from "api/CreditAppApi";
import {
	getAllCustomerInfo,
	getCustomerListByDateRange,
	getCustomerListBySearch,
	getCustomerListBySoldDate,
	getCustomersCurrentAddress,
	updateSoldCustomer,
} from "api/CustomerAPI";
import {
	getLeadListByDateRange,
	getLeadListBySearch,
	saveLead,
} from "api/LeadApi";
import { getVendorListByType } from "api/VendorsAPI";
import Loading from "components/loadingSpinner/Loading";
import ChildrenModal from "components/modals/ChildrenModal";
import ContactTable from "components/tables/ContactTable";
import {
	CustomerStatus,
	MessageZone,
	PATHS,
	customerType,
	vendorTypesOBJ,
} from "constants/Constants";
import dayjs from "dayjs";
import DealTemplateModal from "features/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import VendorsTable from "features/settings/subFeatures/vendor/VendorsTable";
import useRoute from "hooks/useRoute";
import AutomatedVoiceCall from "services/automatedVoiceCallService/AutomatedVoiceCall";
import MailgunEmail from "services/mailgunService/MailgunEmail";
import Twilio from "services/twilioService/Twilio";
import TwilioSendMessage from "services/twilioService/TwilioSendMessage";
import useCurrentCustomerStore from "stores/CustomerStore";
import useCurrentDealStore from "stores/DealStore";
import { useFromUser } from "stores/LocalStorageHelper";
import useCurrentSaleStore from "stores/SaleStore";
import { failAlert, retrieveFailAlert } from "utils/alertUtils";
import history from "utils/createBrowserHistory";
import { showApiError } from "utils/errorRoutingUtils";
import { TemplateVariableProvider } from "../../../../context/TemplateVariableContext";
import useDateRangeStore from "../../../../stores/DateRangeStore";
import InitializeTemplateVars from "../../../../utils/initializeTemplateVars";
import InputElement from "../../../forms/components/InputElement";
import OnlineCreditAppListPage from "../onlineCreditAppListPage/OnlineCreditAppListPage";
import OnlineLeadListPage from "../onlineLeadListPage/OnlineLeadListPage";
import CustomerHeader from "./CustomerHeader";
import { saveLiability } from "../../../../api/LiabilitiesApi";

const CustomerListData = ({
	isDeal = false,
	toggle,
	editCustomer,
	editAddress,
	editRecNum,
}) => {
	// Progress state for handling upload progress
	const [progress, setProgress] = useState(0);

	const today = dayjs().format("YYYY-MM-DD");
	const todayTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
	// Session storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { fromDate, toDate } = useDateRangeStore();

	const initialFromDate = dayjs(fromDate).format("YYYY-MM-DD");
	const initialToDate = dayjs(toDate).format("YYYY-MM-DD");

	const [fromDT, setFromDT] = useState(initialFromDate);
	const [toDT, setToDT] = useState(initialToDate);
	const [name, setName] = useState("");

	const [leadSource, setLeadSource] = useState("");
	const [creditSource, setCreditSource] = useState("");
	//toggle template modal
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);

	const [selectedOption, setSelectedOption] = useState("dateRange");
	// Routes
	const customerDetailsRoute = useRoute(PATHS.CUSTOMER_DETAIL);
	const leadDetailsRoute = useRoute(PATHS.LEAD_DETAIL);

	// Local states
	const [allCustomers, setAllCustomers] = useState([]);
	//const [allLostCustomers, setAllLostCustomers] = useState([]);
	const [leadCount, setLeadCount] = useState(0);
	const [creditAppCount, setCreditAppCount] = useState(0);
	const [loadingList, setLoadingList] = useState(true);
	const [customer, setCustomer] = useState({});
	const [allMessageCustomers, setAllMessageCustomers] = useState([]);
	const [messageCustomers, setMessageCustomers] = useState([]);
	const [messageLeads, setMessageLeads] = useState([]);
	const [messageCreditApps, setMessageCreditApps] = useState([]);
	const [status, setStatus] = useState("Lead");
	// const [personType, setPersonType] = useState("Lead");

	const [viewTextModal, setViewTextModal] = useState(false);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const [customerID, setCustomerID] = useState(null);
	const [lenderCount, setLenderCount] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const handleCollapse = () => {
		setIsOpen(!isOpen);
	};

	const handleFromDateBlur = () => {
		console.log("handleFromDateBlur");
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (diffDays > 730 || diffDays < 0) {
			toDate.setFullYear(fromDate.getFullYear() + 2); // Set toDate to 2 years after fromDate
			setToDT(toDate.toISOString().split("T")[0]);
		}
	};

	const handleToDateBlur = (e) => {
		console.log("handleToDateBlur");
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays > 730) {
			fromDate.setFullYear(toDate.getFullYear() - 2); // Set fromDate to 2 years before toDate
			setFromDT(fromDate.toISOString().split("T")[0]);
		}
	};

	const handleOptionChange = (event) => {
		console.log(event.target.value);
		setSelectedOption(event.target.value);
	};
	const csv = require("csvtojson"); // Import the csvtojson library

	const handleImport = () => {
		console.log("handleImport");
		console.log(leadSource);
		const formData = new FormData(document.getElementById("csvUploadForm"));
		console.log(formData);
		const file = formData.get("csvFile");
		console.log(file);
		if (file) {
			const reader = new FileReader();

			reader.onload = async function (e) {
				console.log(e.target.result);
				const csvData = e.target.result;
				console.log(csvData);

				try {
					setLoadingList(true);
					// Parse the CSV data using csvtojson
					const jsonArray = await csv().fromString(csvData);
					console.log(jsonArray);
					let currentIndex = 0;
					const totalRows = jsonArray.length;

					for (const row of jsonArray) {
						currentIndex++;
						setProgress(Math.round((currentIndex / totalRows) * 100));

						const email = row.EMAIL?.trim().toLowerCase();
						const cellPhone = row["Mobile Phone"]?.trim();
						const firstName = row.FIRST_NAME?.trim().toLowerCase();
						const lastName = row.LAST_NAM?.trim().toLowerCase();
						const isDuplicate = leadSource.some((item) => {
							return (
								(item.email?.toLowerCase() === email ||
									item.cellPhone === cellPhone) &&
								item.firstName?.toLowerCase() === firstName &&
								item.lastName?.toLowerCase() === lastName
							);
						});
						if (isDuplicate) {
							console.log("Duplicate Lead");
							console.log(row);
							continue;
						} else {
							console.log("not Duplicate Lead");
							console.log(row);
						}
						console.log(row);

						let newLead = {
							firstName: firstName,
							lastName: lastName, // Corrected property name
							email: email,
							cellPhone: cellPhone,
							address: row.ADDR1,
							city: row.CITY,
							state: row.STATE,
							zipCode: row.ZIP,
							dateGenerated: todayTime,
							insertedOn: today,
							updatedOn: today,
							toLocationID: locationID,
							middleName: "",
							gender: "",
							businessName: "",
							workPhone: "",
							workPhoneExt: "",
							dob: "",
							phone: "",
							Website: "",
							unit: "",
							country: "",
							dateRead: null,
							leadStatus: 0,
							leadOrigin: null,
							leadOriginName: "Imported",
							message: "",
							dealerNotes: "",
							priceRange: "",
							year: "",
							make: "",
							model: "",
							trim: "",
							Color: "",
							stockNumber: "",
							inventoryID: null,
							customerID: null,
							salesPersonID: null,
							salesPerson2ID: null,
							source: "",
							lastSync: null,
							driverLicense: "",
							driverLicExpDate: null,
							createdBy: "",
							xpnScore: null,
							efxScore: null,
							tuScore: null,
							credit700Url: "",
						};

						try {
							newLead = (await saveLead(newLead)).data.content;
						} catch (error) {
							console.error("Error saving lead:", error);
							console.log(newLead);
							continue; // Skip to next row if saving fails
						}
						console.log(newLead);
						console.log(newLead.leadID);

						if (newLead.leadID) {
							const newLiability = {
								leadID: newLead.leadID,
								customerID: null,
								dealerID: dealerID,
								locationID: locationID,
								collateralType: "Vehicle",
								loanBalance: parseFloat(
									row.CUR_LOAN_BAL.replace(/[^0-9.-]+/g, "")
								),
								payment: parseFloat(
									row.CUR_MTHLY_PMT.replace(/[^0-9.-]+/g, "")
								),
								estAPR: parseFloat(row.EST_APR),
								pmtsMades: row.PMTS_MADE,
								pmtsRemain: row.PMTS_REMAIN,
								fico: row.FICO,
							};
							saveLiability(newLiability);
							console.log(newLiability);
						}
					}
				} catch (error) {
					console.error("Error parsing CSV:", error);
				}
			};
			setProgress(0);
			reader.readAsText(file);
			getOnlineLeads();
			setLoadingList(false);
		}
	};

	const updateSearch = () => {
		console.log("updateSearch");
		updateList();
	};

	// Text message modal
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);

	// Customer store
	const { editID } = useCurrentCustomerStore(
		(state) => ({
			editID: state.editID,
		}),
		shallow
	);

	//deal store
	const { buyer, coBuyer, editBuyerRecNum, editBuyer, editBuyerAddress } =
		useCurrentDealStore(
			(state) => ({
				buyer: state.buyer,
				coBuyer: state.coBuyer,
				editBuyerRecNum: state.editBuyerRecNum,
				editBuyer: state.editBuyer,
				editBuyerAddress: state.editBuyerAddress,
			}),
			shallow
		);

	//customer store
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);

	// Formats data for the list table
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			if (isDeal) container.ID = content[key].ID;
			else container.ID = content[key].id;
			container.firstName = content[key].firstName;
			container.lastName = content[key].lastName;
			container.name = `${content[key].lastName || ""} ${
				content[key].firstName
			}`;
			container.homePhone = content[key].homePhone;
			container.cellPhone = content[key].cellPhone;
			container.email = content[key].email;
			container.license =
				content[key].license != null ? content[key].license : "";
			container.customerType = content[key].type;
			if (content[key].type !== null) {
				container.typeStatus = customerType[content[key].type]?.label;
			} else {
				container.typeStatus = "N/A";
			}
			container.dealType = content[key].dealType;
			container.Actions = (
				<Row style={{ gap: "6px", justifyContent: "center" }}>
					<Button
						className="btn-sm btn-info"
						onClick={(e) => {
							e.stopPropagation();
							setTextHistoryNumber(content[key].cellPhone);
							setCustomerID(content[key].ID);
							toggleViewTextModal();
						}}
					>
						<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
						<span> Chat</span>
					</Button>
					{isDeal ? null : (
						<Button
							className="btn-sm btn-primary"
							onClick={() => {
								handleEdit(content[key]);
							}}
						>
							<i className="icon-big text-center nc-icon nc-paper text-default" />
							&nbsp;View
						</Button>
					)}
					<Button
						className="zoom btn-sm btn-primary"
						//disable the button when it is wholeSale deal and customer type is not business on deal page
						readOnly={
							isDeal
								? (saleType === 1 || saleType === 2) &&
								  (content[key].type === 0 || content[key].type === null)
								: null
						}
						onClick={(e) => {
							e.stopPropagation();
							checkHandleDeal(content[key]);
							console.log(saleType + content[key].type);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
						<span> Deal</span>{" "}
					</Button>
					<Button
						className="zoom btn-sm btn-primary"
						//disable the button when it is wholeSale deal and customer type is not business on deal page
						readOnly={
							isDeal
								? (saleType === 1 || saleType === 2) &&
								  (content[key].type === 0 || content[key].type === null)
								: null
						}
						onClick={(e) => {
							e.stopPropagation();
							checkHandleDeal(content[key]);
							console.log(saleType + content[key].type);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
						<span> Call</span>{" "}
					</Button>
				</Row>
			);
			return container;
		});
		return container;
	};

	// Returns true if selected customer is in a sold deal
	const shouldCloneCustomer = (status) => status === CustomerStatus.SOLD;

	// Calls api to get all current customer info and then api to clone the info as new entries
	const getNewCustomer = async (currentID) => {
		try {
			const allInfo = await getAllCustomerInfo(currentID);
			const newCustomerInfo = await updateSoldCustomer(allInfo.data.content);
			console.log(newCustomerInfo);
			//setCustomer(newCustomerInfo);
			return newCustomerInfo.data.content.customer;
		} catch (err) {
			if (!err.isGeneralError) {
				showApiError(err, retrieveFailAlert);
			}
		}
	};

	// Handles the edit click
	const handleEdit = async (customer) => {
		let { ID: customerID } = customer;

		if (shouldCloneCustomer(customer.customerStatus)) {
			let newCust = await getNewCustomer(customerID);

			customerID = newCust?.ID;
		}

		editID(customerID);

		if (status === "Lead") {
			history.push(leadDetailsRoute, { ID: customerID });
		} else {
			history.push(customerDetailsRoute, { ID: customerID });
		}
	};

	//check the cobuyer and buyer is the same
	const checkHandleDeal = (customer) => {
		let canHandleDeal = true;
		if (isDeal) {
			if (buyer?.ID) {
				if (
					buyer?.originalID === customer.originalID ||
					coBuyer?.originalID === customer.originalID
				) {
					failAlert("Already selected as Buyer or Co-Buyer");
					canHandleDeal = false;
				}
			}
		}
		if (canHandleDeal) handleDeal(customer);
	};

	// Calls endpoint to clone customer information and then route to deal add page
	const handleDeal = async (customer) => {
		let currentCustomer = { ...customer };

		if (shouldCloneCustomer(customer.customerStatus)) {
			currentCustomer = await getNewCustomer(customer.ID);
		}

		setCustomer(currentCustomer);
		getAddress(currentCustomer);

		if (!isDeal) {
			toggleTemplate();
			editBuyerRecNum(currentCustomer.id);
		} else {
			editRecNum(currentCustomer.ID);
			toggle();
		}
	};

	// Gets the customer or lead list data and and calls function to format it for the table
	const handleListResponse = (data, setData) => {
		const result = dataFormat(data);
		let res = result;
		if (saleType === 1 || saleType === 2) {
			res = result.filter((item) => item.customerType === 1);
		} else {
			if (isDeal) res = result.filter((item) => item.customerType === 0);
		}
		setData(res);
		setLoadingList(false);
	};

	const handleLeadResponse = (data) => {
		setLeadCount(data.NumOfRecord);
		setLeadSource(data.OnlineLeads);
		setLoadingList(false);
	};

	const handleCreditAppResponse = (data) => {
		setCreditAppCount(data.CreditApp.length);
		setCreditSource(data.CreditApp);
		setLoadingList(false);
	};

	const getCustomers = () => {
		console.log("ID: " + dealerID + " " + locationID + " ");

		if (dealerID != null && locationID != null) {
			if (selectedOption === "dateRange")
				if (isDeal) {
					getCustomerListByDateRange(
						locationID,
						dayjs(fromDT).format("MM/DD/YYYY"),
						dayjs(toDT).format("MM/DD/YYYY")
					).then(
						(response) =>
							handleListResponse(
								response.data.content.customers,
								setAllCustomers
							),
						(err) => {
							if (!err.isGeneralError) {
								showApiError(err, retrieveFailAlert);
							}
						}
					);
				} else {
					getCustomerListBySoldDate(
						locationID,
						dayjs(fromDT).format("MM/DD/YYYY"),
						dayjs(toDT).format("MM/DD/YYYY")
					).then(
						(response) =>
							handleListResponse(
								response.data.content.customers,
								setAllCustomers
							),
						(err) => {
							if (!err.isGeneralError) {
								showApiError(err, retrieveFailAlert);
							}
						}
					);
				}
			if (selectedOption === "customer" && name !== "") {
				let xfilter = "";
				xfilter = " (firstName + ' ' + lastName) like '%" + name + "%'  ";
				getCustomerListBySearch(locationID, xfilter)
					.then((response) => {
						handleListResponse(
							response.data.content.customers,
							setAllCustomers
						);
					})
					.catch((error) => {
						showApiError(error, retrieveFailAlert);
					});
			}
			//setFilter(` (firstName + ' ' + lastName) like '%${customer}%' `);
		}
	};

	// Gets a list of all the Leads for this dealer and location
	const getOnlineLeads = () => {
		console.log(fromDate, toDate);
		console.log(fromDT, toDT);
		//		setLoadingList(true);

		if (selectedOption === "dateRange")
			getLeadListByDateRange(
				locationID,
				dayjs(fromDT).format("MM/DD/YYYY"),
				dayjs(toDT).format("MM/DD/YYYY")
			).then(
				(response) => {
					console.log(response);
					handleLeadResponse(response.data.content);

					//					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
					//					setLoadingList(false);
				}
			);
		if (selectedOption === "customer" && name !== "") {
			let xfilter = "";
			xfilter = " (ContactFname + ' ' + ContactLname) like '%" + name + "%'  ";
			getLeadListBySearch(locationID, xfilter)
				.then((response) => {
					handleLeadResponse(response.data.content);

					// setLeadCount(response.data.content.NumOfRecord);
					// setLeadSource(response.data.content.OnlineLeads);
					//					setLoadingList(false);
				})
				.catch((error) => {
					showApiError(error, retrieveFailAlert);
					//					setLoadingList(false);
				});
		}
	};

	// Gets a list of all the Lenders for  location
	const getLendersList = () => {
		getVendorListByType(locationID, vendorTypesOBJ.LENDER.value).then(
			(response) => {
				setLenderCount(response.data.content.length);
				setLoadingList(false);
			}
		);
	};

	// Gets a list of all the CreditApps for this dealer and location
	const getCreditApps = () => {
		if (selectedOption === "dateRange")
			getCreditAppListByDateRange(
				locationID,
				dayjs(fromDT).format("MM/DD/YYYY"),
				dayjs(toDT).format("MM/DD/YYYY")
			).then(
				(response) => {
					handleCreditAppResponse(response.data.content);

					//		setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						//			setLoadingList(false);
					}
				}
			);
		if (selectedOption === "customer" && name !== "") {
			let xfilter = "";
			xfilter = " (firstName + ' ' + lastName) like '%" + name + "%'  ";
			getCreditAppListBySearch(locationID, xfilter)
				.then((response) => {
					setCreditAppCount(response.data.content.CreditApp.length);
					setCreditSource(response.data.content.CreditApp);
					//						setLoadingList(false);
				})
				.catch((error) => {
					showApiError(error, retrieveFailAlert);
					//					setLoadingList(false);
				});
		}
	};

	// Gets customers current address
	const getAddress = (customer) => {
		if (customer.ID != null) {
			getCustomersCurrentAddress(customer.ID).then(
				(res) => {
					if (!isDeal) {
						editBuyerRecNum(customer.ID);
						editBuyer(customer);
						editBuyerAddress(res.data.content);
					} else {
						editRecNum(customer.ID);
						editAddress(res.data.content);
						editCustomer(customer);
					}
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			failAlert("Failed to add customer to deal. Please try again later.");
		}
	};

	// Modify the handleRowClick to use handleEdit
	const handleRowClick = (customer) => {
		handleEdit(customer);
	};

	useEffect(() => {
		console.log("useEffect message");
		setAllMessageCustomers([
			...messageCustomers,
			...messageLeads,
			...messageCreditApps,
		]);
	}, [messageCustomers, messageLeads, messageCreditApps]);

	// TODO is this necessary?
	useEffect(() => {
		setLoadingList(true);
		if (status === "Customer") {
			getCustomers();
		} else if (status === "Lead") {
			getOnlineLeads();
		} else if (status === "CreditApp") {
			getCreditApps();
		} else if (status === "Lender") {
			getLendersList();
		}

		console.log("status: " + status);
		// eslint-disable-next-line
	}, [status]);
	// useEffect(() => {
	// 	setLoadingList(true);
	// 	if (status === "Lead") getOnlineLeads();
	// 	console.log("status: " + status);
	// 	// eslint-disable-next-line
	// }, [!loadingList]);

	const getAll = async () => {
		getCustomers();
		getOnlineLeads();
		getCreditApps();
		getLendersList();
	};

	const updateList = () => {
		if (isDeal) {
			getCustomers();
		} else {
			console.log(fromDate, toDate);
			getAll();
		}
	};

	useEffect(() => {
		console.log("useEffect fromDatetoDate");
		updateList();
		// eslint-disable-next-line
	}, [fromDate, toDate]);

	const customerFilter = () => {
		return (
			<>
				<Card className="mb-2 mx-3">
					{/* <CardHeader
						onClick={handleCollapse}
						className="cursor-pointer d-flex justify-content-between align-items-center py-2"
					>
						<h5 className="mb-0">Advanced Search</h5>
						<Button className="btn-link btn-sm">
							<i
								className={`nc-icon ${
									isOpen ? "nc-minimal-up" : "nc-minimal-down"
								}`}
							/>
						</Button>
					</CardHeader> */}
					<CardHeader>
						<h5 className="mb-0">Advanced Search</h5>
					</CardHeader>

					{/* <Collapse isOpen={isOpen}> */}
					<CardBody>
						<Row>
							<Col xs={12} md={6}>
								<Card style={{ width: "fit-content", float: "right" }}>
									<CardBody className="pb-2">
										{/* Date Range Option */}
										<FormGroup className="d-flex align-items-center mb-1">
											<input
												type="radio"
												name="searchOption"
												value="dateRange"
												checked={selectedOption === "dateRange"}
												onChange={handleOptionChange}
											/>
											<Row>
												<InputElement
													disableValidation={true}
													value={fromDT}
													onBlur={handleFromDateBlur}
													onChange={(e) => setFromDT(e.target.value)}
													name="fromDate"
													label="From Date"
													type="date"
													colSize="col-md-12 col-lg-6 mb-0 col-sm-12"
												/>
												<InputElement
													disableValidation={true}
													value={toDT}
													onBlur={handleToDateBlur}
													onChange={(e) => setToDT(e.target.value)}
													name="toDate"
													label="To Date"
													type="date"
													colSize="col-md-12 col-lg-6 mb-0 col-sm-12"
												/>
											</Row>
										</FormGroup>
										<Col style={{ fontSize: ".8rem" }}>
											*The date range cannot exceed 2 years.
										</Col>
									</CardBody>
								</Card>
							</Col>
							<Col xs={12} md={6}>
								{/* Customer Name Option */}
								<Card style={{ width: "fit-content" }}>
									<CardBody>
										<FormGroup className="d-flex align-items-center">
											<input
												type="radio"
												name="searchOption"
												value="customer"
												checked={selectedOption === "customer"}
												onChange={handleOptionChange}
											/>
											<div className="flex-grow-1">
												<InputElement
													disableValidation={true}
													value={name}
													name="name"
													label="Customer Name"
													type="text"
													onChange={(e) => setName(e.target.value)}
													colSize="mb-0 col-sm-12 maxInput"
												/>
											</div>
										</FormGroup>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<div className="text-center mt-3">
							<Button size="sm" color="primary" onClick={updateSearch}>
								<i className="nc-icon nc-zoom-split" /> Search
							</Button>
						</div>
					</CardBody>
					{/* </Collapse> */}
				</Card>
				<Col className="mb-2">
					{status !== "Lender" && (
						<Col xs={12}>
							<TemplateVariableProvider>
								<InitializeTemplateVars messageZone={status}>
									<Twilio
										blastMessage
										customers={allMessageCustomers}
										setCustomers={setAllMessageCustomers}
										personType={status}
									/>
									<MailgunEmail
										blastMessage
										customers={allMessageCustomers}
										setCustomers={setAllMessageCustomers}
										personType={status}
									/>
									<AutomatedVoiceCall
										vapiAssistantId="6b7fa245-f541-41ec-95c8-3c3d5458dfd9"
										phoneNumbers={allMessageCustomers
											.map(
												(customer) => customer.cellPhone || customer.homePhone
											)
											.filter(Boolean)}
										onSuccess={(data) => {
											console.log("Calls initiated:", data);
											setAllMessageCustomers([]);
										}}
										onError={(error) => {
											console.error("Calls failed:", error);
										}}
									/>
									{status === "Lead" && (
										<Button className="btn-md btn-info">
											{progress !== 0 && progress < 100 && (
												<div className="text-center mb-5 w-75">
													<Progress striped max="100" value={progress}>
														<span className="progress-value">{progress}%</span>
													</Progress>
												</div>
											)}
											{(progress === 0 || progress === 100) && (
												<form
													id="csvUploadForm"
													// action="/upload"
													// method="post"
													// enctype="multipart/form-data"
												>
													<label for="csvFile">Upload CSV file here :</label>
													<input
														type="file"
														id="csvFile"
														name="csvFile"
														accept=".csv"
														required
													></input>
													<button
														type="button"
														onClick={() => {
															handleImport();
														}}
													>
														Upload
													</button>
												</form>
											)}
										</Button>
									)}
								</InitializeTemplateVars>
							</TemplateVariableProvider>
						</Col>
					)}
				</Col>
			</>
		);
	};

	return (
		<div className="content" style={{ minHeight: "1000px" }}>
			{loadingList && <Loading />}
			{!loadingList && (
				<>
					{!isDeal && (
						<>
							<CustomerHeader
								status={status}
								setStatus={setStatus}
								leadCount={leadCount}
								creditAppCount={creditAppCount}
								customerCount={allCustomers.length}
								lenderCount={lenderCount}
							/>

							{customerFilter()}
							{status === "Lead" && (
								<TemplateVariableProvider>
									<InitializeTemplateVars messageZone={MessageZone.LEAD}>
										<OnlineLeadListPage
											setMessageCustomers={setMessageLeads}
											leadSource={leadSource}
											//getAll={getOnlineLeads}
										/>
									</InitializeTemplateVars>
								</TemplateVariableProvider>
							)}
							{status === "CreditApp" && (
								<TemplateVariableProvider>
									<InitializeTemplateVars messageZone={MessageZone.CREDITAPP}>
										<OnlineCreditAppListPage
											setMessageCustomers={setMessageCreditApps}
											creditSource={creditSource}
										/>
									</InitializeTemplateVars>
								</TemplateVariableProvider>
							)}
							{status === "Customer" && (
								<TemplateVariableProvider>
									<InitializeTemplateVars messageZone={MessageZone.CUSTOMER}>
										<ContactTable
											data={allCustomers}
											setMessageCustomers={setMessageCustomers}
											onRowClick={handleRowClick}
											isDeal={isDeal}
										/>
									</InitializeTemplateVars>
								</TemplateVariableProvider>
							)}
							{status === "Lender" && (
								<TemplateVariableProvider>
									<InitializeTemplateVars messageZone={MessageZone.LENDER}>
										<Card className="mx-3">
											<CardBody>
												<VendorsTable
													refresh={false}
													type={30}
													showChat={true}
												/>
											</CardBody>
										</Card>
									</InitializeTemplateVars>
								</TemplateVariableProvider>
							)}
						</>
					)}
					{isDeal && (
						<>
							{customerFilter()}

							<ContactTable
								data={allCustomers}
								setMessageCustomers={setMessageCustomers}
								onRowClick={handleRowClick}
								hideCheckboxColumn={true}
								hideActionsColumn={true}
								isDeal={isDeal}
							/>
						</>
					)}
				</>
			)}
			{!isDeal && (
				<ChildrenModal
					modalTitle="Deal Templates"
					modal={templateModal}
					toggle={toggleTemplate}
				>
					<DealTemplateModal customer={customer} />
				</ChildrenModal>
			)}
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TemplateVariableProvider>
					<InitializeTemplateVars messageZone={status}>
						<TwilioSendMessage
							sendToNumber={textHistoryNumber}
							customerID={customerID}
							personType={status}
						/>
					</InitializeTemplateVars>
				</TemplateVariableProvider>
			</ChildrenModal>
		</div>
	);
};

export default CustomerListData;
