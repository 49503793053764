import React, { useEffect, useState } from "react";

import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import useRoute from "hooks/useRoute";
import ChildrenModal from "components/modals/ChildrenModal";
import DealTemplateModal from "features/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import Loading from "components/loadingSpinner/Loading";

import { getSettings } from "api/SettingsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/Constants";

import dayjs from "dayjs";
import shallow from "zustand/shallow";
import { buildNewDeal } from "api/DealAPI";
import useUserPrivileges from "../../settings/subFeatures/settingsTabs/userManagement/useUserPrivileges";
import UnauthorizedContent from "../subFeatures/dealTabs/tabsContainer/UnauthorizedContent";

const DealAddPage = () => {
	// Date objects
	const today = dayjs().format("YYYY-MM-DD");
	const dealNum = dayjs().format("MMDDYY-HHmmss");
	const privileges = useUserPrivileges();
	// Grab information from local storage
	const dcLoginID = useFromUser("ID");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	// Routes
	const history = useHistory();
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);
	const dashboardRoute = useRoute(PATHS.DASHBOARD);

	// Local states
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);

	// Sale store
	const { editDealNumber, editLoanDate, editFirstDueDate, editFinalDueDate } =
		useCurrentSaleStore(
			(state) => ({
				editDealNumber: state.editDealNumber,
				editLoanDate: state.editLoanDate,
				editFirstDueDate: state.editFirstDueDate,
				editFinalDueDate: state.editFinalDueDate,
			}),
			shallow
		);

	// Deal store,
	const {
		isLoading,
		editIsLoading,
		editDealSettings,
		editSalesmanRecNum,
		editSalesPeople,
		editLenders,
		editTradeVendors,
		editWarrantyVendors,
		editSellerFeeTypes,
		editDmvFeeTypes,
		editGapCompanies,
		editCreatedBy,
		editModifiedBy,
		editModifiedById,
		editCreatedById,
		editCreatedOn,
		editModifiedOn,
		editCredit700Lenders,
	} = useCurrentDealStore(
		(state) => ({
			isLoading: state.isLoading,
			editIsLoading: state.editIsLoading,
			editDealSettings: state.editDealSettings,
			editSalesmanRecNum: state.editSalesmanRecNum,
			editSalesPeople: state.editSalesPeople,
			editLenders: state.editLenders,
			editTradeVendors: state.editTradeVendors,
			editWarrantyVendors: state.editWarrantyVendors,
			editSellerFeeTypes: state.editSellerFeeTypes,
			editDmvFeeTypes: state.editDmvFeeTypes,
			editGapCompanies: state.editGapCompanies,
			editCreatedBy: state.editCreatedBy,
			editModifiedBy: state.editModifiedBy,
			editModifiedById: state.editModifiedById,
			editCreatedById: state.editCreatedById,
			editCreatedOn: state.editCreatedOn,
			editModifiedOn: state.editModifiedOn,
			editCredit700Lenders: state.editCredit700Lenders,
		}),
		shallow
	);

	// Initialize deal store with the deal settings and then route to deal detail
	// page when no templates are found or modal is closed without template selection
	const initializeNewDealObj = () => {
		Promise.all([
			getSettings(dealerID, locationID),
			buildNewDeal(locationID),
		]).then(
			(res) => {
				const settingsRes = res[0].data.content;
				const newDealRes = res[1].data.content;
				editDealSettings(settingsRes);
				editDealNumber(dealNum);
				editLoanDate(today);
				editFirstDueDate(today);
				editFinalDueDate(today);
				editIsLoading(false);
				editSalesmanRecNum(dcLoginID);

				editSalesPeople(newDealRes.salesPeople);
				editLenders(newDealRes.lenders);
				editTradeVendors(newDealRes.tradeVendors);
				editWarrantyVendors(newDealRes.warrantyVendors);
				editSellerFeeTypes(newDealRes.sellerFeeTypes);
				editDmvFeeTypes(newDealRes.dmvFeeTypes);
				editGapCompanies(newDealRes.gapCompanies);
				editCreatedBy(lastName + ", " + firstName);
				editModifiedBy(lastName + ", " + firstName);
				editModifiedById(dcLoginID);
				editCreatedById(dcLoginID);
				editCreatedOn(today);
				editModifiedOn(today);
				editCredit700Lenders(newDealRes.credit700Lenders);
				toggleTemplate();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(dashboardRoute);
				}
			}
		);
	};

	// Resets store information and gets the list of all the dealer's template
	// upon initial render
	useEffect(() => {
		// TODO may need to update this and utilize useSetDeal()
		initializeNewDealObj();
		// eslint-disable-next-line
	}, []);

	const p = privileges();
	console.log(p);
	if (!p.dealNew) {
		return <UnauthorizedContent />;
	} else {
		return (
			<div>
				{(isLoading && <Loading />) || (
					<ChildrenModal
						modal={templateModal}
						toggle={toggleTemplate}
						modalTitle="Deal Templates"
						onClosed={() => {
							history.push(dealDetailPage);
						}}
					>
						<DealTemplateModal />
					</ChildrenModal>
				)}
			</div>
		);
	}
};

export default DealAddPage;
