import createAxiosInstance from "../utils/apiUtils";

import { getJwtToken } from "../utils/authUtils";
import { userBaseUrl } from "../constants/Constants";
//import { LOCAL } from "constants/Constants";

import axios from "axios";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/",
});

const responseInstance = createAxiosInstance(
	{
		baseURL: userBaseUrl,
	},
	false
);

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/",
// });

// PepperPay
export const createPepperPayMerchant = async (locationID, body) => {
	return await instance.post(`PepperPay/signup/${locationID}`, body);
};
export const getPublicKey = async (locationID) => {
	return await instance.get(`PepperPay/pubKey/${locationID}`);
};
export const processTransaction = async (locationID, body) => {
	return await instance.post(`PepperPay/transaction/${locationID}`, body);
};
export const getTerminals = async (locationID) => {
	return await instance.get(`PepperPay/terminal/${locationID}`);
};
export const processTerminalTransaction = async (locationID, body) => {
	return await instance.post(
		`PepperPay/terminal/transaction/${locationID}`,
		body
	);
};

// DC vantage tables
export const getPartnerLinks = async (locationID) => {
	return await instance.get(`integrations/partnerLinks/${locationID}`);
};

export const getPartnerSend = async (locationID) => {
	return await instance.get(`integrations/partnerSend/${locationID}`);
};

export const savePartnerLinks = async (body) => {
	return await instance.post(`integrations/partnerLinks/save`, body);
};

//UACC
export const linkUACDealer = async (locationID) => {
	return await instance.get(`UAC/link-dealers/${locationID}`);
};

export const checkUACSubmitted = async (dealID) => {
	return await instance.get(`UAC/${dealID}`);
};

export const getUACAppStatus = async (dealID) => {
	return await instance.get(
		`UAC/Application-Status/${dealID}`
		//`UAC/TEST/Application-Status/${dealID}`
	);
};

export const submitUACApp = async (dealID) => {
	return await instance.get(
		`UAC/Submit-Application/${dealID}`
		//	`UAC/TEST/Submit-Application/${dealID}`
	);
};

export const submitRouteOne = async (dealID) => {
	return await instance.get(`RouteOne/Submit-Application/${dealID}`);
};

// 700 credit
export const getCustomerCredit700History = async (ID) => {
	return await instance.get(`credit700/${ID}`);
};

// submit/credit/{BUREAU}/{customerID}
export const submitCreditCheck = async (ID, BUREAU) => {
	return await instance.get(`credit700/submit/credit/${BUREAU}/${ID}/`);
};

export const submitPrescreen = async (ID, BUREAU) => {
	return await instance.get(`credit700/submit/prescreen/${BUREAU}/${ID}/`);
};

// los can be one of "R1", "DT", "CUDL", or "APPONE"
export const submitToLenders = async (dealID, los) => {
	return await instance.get(`credit700/submit/lender/${los}/${dealID}`);
};

export const submitPublicPrescreen = async (prescreen) => {
	return await instance.post(`credit700/public/submit/prescreen`, prescreen);
};

//carFax

export const getCarfaxAccessToken = async (locationID, code) => {
	return await axios.get(
		`${userBaseUrl}/inv/carfax/token/${locationID}/${code}`,
		{
			headers: {
				Authorization: getJwtToken(),
			},
		}
	);
};

export const getCarfaxReport = async (dealerID, locationID, vin) => {
	return await axios.get(
		`${userBaseUrl}/inv/carfax/report/${dealerID}/${locationID}/${vin}?type=dc`,
		{
			headers: {
				Authorization: getJwtToken(),
			},
		}
	);
};

export const getCarfaxReports = async (dealerID, locationID, vinsArray) => {
	return await axios.post(
		`${userBaseUrl}/inv/carfax/reports/${dealerID}/${locationID}?type=dc`,
		vinsArray,
		{
			headers: {
				Authorization: getJwtToken(),
			},
		}
	);
};

// this must be some kind of old carfax endpoint?
export const getCarfax = async (data) => {
	return await responseInstance.get(
		`https://www.dealernetwork.com/CarfaxProcessor2.aspx`,
		{ params: data }
	);
};
//auto check
export const getAutoCheck = async (inventoryID) => {
	return await instance.get(`autocheck/${inventoryID}`);
};
export const getAutoCheckByVin = async (locationID, vin) => {
	return await instance.get(`autocheck/${locationID}/${vin}`);
};

export const sendMailgunEmail = async (email) => {
	return await instance.post(`mailgun/email/send`, email);
};

export const sendMailgunBlastEmail = async (emails) => {
	return await instance.post(`mailgun/email/blast/send`, emails);
};

export const getDealerInsuranceInfo = async (locationID) => {
	return await instance.get(`dealerInsuranceInfo/${locationID}`);
};
export const saveDealerInsuranceInfo = async (body) => {
	return await instance.post(`dealerInsuranceInfo/save`, body);
};
export const saveSchedulerMessage = async (body) => {
	return await instance.post(`scheduler/message/save`, body);
};
export const getScheduledList = async (locationID) => {
	//
	return await instance.get(`scheduler/scheduledList/${locationID}`);
};
// @GetMapping("scheduledListByFilter/{locationID}")
// public SuccessfulResponse getScheduledListByFilter(@PathVariable Integer locationID,
// 		@RequestParam String scheduleType, @RequestParam String source, @RequestParam String sourceID,
// 		@RequestParam String phone) {
export const getScheduledListByFilter = async (
	locationID,
	reminderMode,
	source,
	sourceID,
	phone
) => {
	console.log({ locationID, reminderMode, source, sourceID, phone });
	return await instance.get(`scheduler/scheduledListByFilter/${locationID}`, {
		params: {
			reminderMode,
			source,
			sourceID,
			phone,
		},
	});
};
