import React, { useState, useEffect } from "react";

const useUserPrivileges = () => {
	const user = JSON.parse(sessionStorage.getItem("user"));
	const p = JSON.parse(user.userPrivileges)?.privileges;
	return () => {
		return p;
	};
};

export default useUserPrivileges;
