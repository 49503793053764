import React, { useState, useEffect } from "react";
import Commission from "../commissionsTab/Commission";
import LenderIntegrations from "../lendersTab/LendersTab";
import Forms from "../formsTab/FormsTab";
import FairFaxTab from "../fairfaxTab/FairFaxTab";
import DealPageDealSettings from "../settingsTab/DealPageDealSettings";
import useIsOwnerOrAdmin from "features/settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import UnauthorizedContent from "./UnauthorizedContent";
import TradeTab from "../tradeTab/TradeTab";
import { DealContent } from "../deskingTab/DeskingTab";
import ShippingForm from "../shippingTab/ShippingForm";
import InitializeTemplateVars from "utils/initializeTemplateVars";
import { TemplateVariableProvider } from "context/TemplateVariableContext";
import ChildrenModal from "components/modals/ChildrenModal";
import { saveDeal } from "api/DealAPI";
import useSetDeal from "features/deal/hooks/useSetDeal";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { DealStatus, MessageZone } from "constants/Constants";
import DealActionBar from "features/deal/components/DealActionBar";
import FormsModal from "features/forms/components/FormsModal";
import DealTitle from "features/deal/components/DealTitle";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import useCalculateSales from "../../../hooks/useCalculateSales";
import NavTabsHolder from "components/tabs/TabsHolder";
import useCurrentSaleStore from "../../../../../stores/SaleStore";
import BullkWholesaleTab from "../deskingTab/BullkWholesaleTab";
import BullkRetailTab from "../deskingTab/BullkRetailTab";
import useUserPrivileges from "../../../../settings/subFeatures/settingsTabs/userManagement/useUserPrivileges";

const DealTabs = () => {
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const [openForm, setOpenForm] = useState(false);
	const [tabComponents, setTabComponents] = useState([]);
	//eslint-disable-next-line
	const [activeTabName, setActiveTabName] = useState("");
	const privileges = useUserPrivileges();
	const p = privileges();
	const tabs = [
		"Desking",
		"Trade",
		"Lenders",
		"Forms",
		"eTags/Titling",
		"Commissions",
		"Shipping",
		"Settings",
	];

	const toggleOpenForm = () => setOpenForm(!openForm);

	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);
	console.log(saleType);

	const save = (deal) => {
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const handleTabChange = (tabName) => {
		setActiveTabName(tabName);
		const deal = getDeal();
		console.log(deal.deal.type);
		// if (deal.deal.type === DealStatus.PENDING) {
		if (tabName === "Forms") {
			toggleOpenForm();
		} else {
			//const deal = getDeal();
			save(deal);
		}
		// }
	};

	useEffect(() => {
		setTabComponents([
			(saleType === 3 && <BullkWholesaleTab key="desking" />) ||
				(saleType === 4 && <BullkRetailTab key="desking" />) || (
					<DealContent key="desking" />
				),

			<TradeTab key="trade" />,
			<LenderIntegrations key="lenders" />,
			(p.dealFormsTab && <Forms key="forms" />) || (
				<UnauthorizedContent key="unauthorized-forms" />
			),
			<FairFaxTab key="fairfax" />,
			(p.dealCommissionsTab && <Commission key="commission" />) || (
				<UnauthorizedContent key="unauthorized-commission" />
			),
			<ShippingForm key="shipping" />,
			(isOwnerOrAdmin && <DealPageDealSettings key="settings" />) || (
				<UnauthorizedContent key="unauthorized-settings" />
			),
		]);
	}, [isOwnerOrAdmin]);

	const { buyerRecNum } = useCurrentDealStore(
		(state) => ({
			buyerRecNum: state.deal.buyerRecNum,
		}),
		shallow
	);

	useCalculateSales();

	return (
		<>
			<TemplateVariableProvider>
				<InitializeTemplateVars messageZone={MessageZone.DEAL}>
					<DealActionBar />
				</InitializeTemplateVars>
				<DealTitle />
				<NavTabsHolder
					tabs={buyerRecNum === null ? ["Desking"] : tabs}
					tabComponents={tabComponents}
					navClass="nav-pills-primary justify-content-center nav nav-pills"
					getTabName={handleTabChange}
				/>
				{p.dealFormsTab && (
					<ChildrenModal
						toggle={toggleOpenForm}
						modal={openForm}
						size="md"
						hideX={true}
					>
						<FormsModal toggle={toggleOpenForm} />
					</ChildrenModal>
				)}
			</TemplateVariableProvider>
		</>
	);
};

export default DealTabs;
